import React from "react";
import { Container } from "react-bootstrap";
import SHTText from "../../components/SHTText";
import "../../assets/styles/page/about_featured_style.css";
import SHTButton from "../../components/SHTButton";

const MediaContent = () => {
    return (
        <Container>
            <section
                className="d-sm-block sht_container_space about_section_container "
                style={{ marginTop: 100, marginBottom: 100 }}
            >

                <div className="p-6 bg-gray-800 text-white">
                    {/* Section 1: For Teams’ Social Media Managers */}
                    <SHTText size="h4" text="1. For Teams’ Social Media Managers" color="white" />

                    <div className="py-3">
                        <SHTText size="h6" text="Accreditation Form:" color="white" />
                        <SHTText size="p" text="Please ensure that the media accreditation form is filled out completely and accurately." color="gray" />
                    </div>

                    <div className="py-3">
                        <SHTText size="h6" text="Phone Access:" color="white" />
                        <SHTText size="p" text="Social media managers using phones will have free access to shoot content while strictly following our media guidelines." color="gray" />
                    </div>

                    <div className="py-3">
                        <SHTText size="h6" text="Dress Code & Media Zones:" color="white" />
                        <SHTText size="p" text="Managers must wear their team uniform, jersey, or any form of identification that connects them to the team during game times. They are required to shoot only from designated areas and exit these areas promptly after each match." color="gray" />
                    </div>

                    <div className="py-3">
                        <SHTText size="h6" text="Pre-Arrival Requirements:" color="white" />
                        <SHTText size="p" text="Kindly share your social media manager’s name and picture of ID before arriving at the pitch." color="gray" />
                    </div>

                    <div className="py-3">
                        <SHTText size="h6" text="Game Promotion:" color="white" />
                        <SHTText size="p" text="It is mandatory to post 2 to 4 times per week and tag @sfflshowtime on Instagram and @showtime flag on YouTube and X (Twitter) platforms respectively. If you require any content from us to post, please feel free to reach out to the league." color="gray" />
                        <SHTText size="p" text="It is essential for teams to actively promote content related to game days, match fixtures, ticket sales, and promos, as this helps elevate both the teams' and the league's visibility." color="gray" />
                    </div>

                    <div className="py-3">
                        <SHTText size="h6" text="Violation of Guideline:" color="white" />
                        <SHTText size="p" text="Any violation of content, game day, or ticket promotion guidelines will result in a fine of 5,000 naira for every week in which posts are omitted." color="gray" />
                    </div>

                    {/* Section 2: For Media And Independent Content Creators */}
                    <SHTText size="h4" text="2. For Media And Independent Content Creators" color="white" />

                    <div className="py-3">
                        <SHTText size="h6" text="Accreditation Process:" color="white" />
                        <SHTText size="p" text="Independent media and content creators working with your team must adhere to the league’s accreditation process. A rate card applies for their content creation services. Please contact us for more details." color="gray" />
                    </div>

                    {/* Section 3: Accreditation Rules for All Media And Independent Content Creators */}
                    <SHTText size="h4" text="3. Accreditation Rules for All Media And Independent Content Creators" color="white" />

                    <div className="py-3">
                        <SHTText size="h6" text="Identification:" color="white" />
                        <SHTText size="p" text="All accredited individuals must wear their Media Accreditation Pass/Tags at all times during games or events. These tags are the property of the League and will be provided on game days." color="gray" />
                    </div>

                    <div className="py-3">
                        <SHTText size="h6" text="Tag Return:" color="white" />
                        <SHTText size="p" text="Media tags must be returned to the league officials at the end of each game day or event." color="gray" />
                    </div>

                    <div className="py-3">
                        <SHTText size="h6" text="Visible Accreditation:" color="white" />
                        <SHTText size="p" text="Accreditations must be visible throughout the event. They are non-transferable and specific to the individual who received them. Any violation of this rule may result in accreditation loss for all involved." color="gray" />
                    </div>

                    <div className="py-3">
                        <SHTText size="h6" text="Revocation:" color="white" />
                        <SHTText size="p" text="Accreditations may be revoked at any time without prior notice for non-compliance." color="gray" />
                    </div>

                    {/* Section 4: Accreditation Privileges */}
                    <SHTText size="h4" text="4. Accreditation Privileges" color="white" />

                    <div className="py-3">
                        <SHTText size="p" text="Accredited members will receive access to the game days, media work areas, and designated media spaces in the venue. Misuse of accreditation will result in immediate revocation of access and possible disqualification from future events." color="gray" />
                    </div>

                    <div className="py-6">
                        <SHTText size="p" text="We appreciate your cooperation in following these guidelines to help us maintain a professional and organized atmosphere at our games." color="gray" />
                        <SHTText size="p" text="For any inquiries or further details, please don't hesitate to reach out." color="gray" />
                    </div>

                    <div className="py-3">
                        <SHTText size="h6" text="Click the button below to download Media Accreditation Application Form " color="white" />
                        <div className="py-3">
                            <a href="https://asset.cloudinary.com/dprime/0fab34194df2baeeb2b707c1258bc678" >
                                <SHTButton
                                    type="submit"
                                    name="Download form"
                                    color="primary"
                                    size="sm"
                                    handleClick={null}
                                />
                            </a>
                        </div>

                    </div>

                </div>

            </section>
        </Container>
    );
};

export default MediaContent;

import React from "react";
import { Container } from "react-bootstrap";
import SHTNoResult from "../../components/SHTNoResult";
import SHTScoreCard from "../../components/SHTScoreCard";
import SHTPagination from "../../components/SHTPagination";

interface propsInterface {
  scoreCardDetails: any[];
  loading: boolean;
  handleClick: Function;
  handleViewGameDetails: Function;
  handlePageChange: Function;
  totalPages: number;
  currentPage: number;
}

const ScoreDetails = (props: propsInterface) => {
  const {
    scoreCardDetails,
    loading,
    handleClick,
    handleViewGameDetails,
    handlePageChange,
    totalPages,
    currentPage,
  } = props;

  return (
    <section>
      <Container>
        {scoreCardDetails.length > 0 ? (
          <>
            {scoreCardDetails.map((cardDetails) => (
              <div key={cardDetails.id} className="py-3">
                <SHTScoreCard
                  firstImage={cardDetails.firstImage}
                  secondImage={cardDetails.secondImage}
                  date={cardDetails.date}
                  time={cardDetails.time}
                  firstScore={cardDetails.firstScore}
                  secondScore={cardDetails.secondScore}
                  firstTeam={cardDetails.firstTeam}
                  secondTeam={cardDetails.secondTeam}
                  handleOnClick={() => handleClick(cardDetails.id)}
                  handleViewGameDetails={() =>
                    handleViewGameDetails(cardDetails.id, cardDetails.team1Id)
                  }
                />
              </div>
            ))}
            <SHTPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </>
        ) : (
          <SHTNoResult loading={loading} />
        )}
      </Container>
    </section>
  );
};

export default ScoreDetails;

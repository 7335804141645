import React from "react";
import { Container } from "react-bootstrap";
import SHTText from "../../components/SHTText";
import "../../assets/styles/page/about_featured_style.css";

const MediaContent = () => {
    return (
        <Container>
            <section
                className="d-sm-block sht_container_space about_section_container "
                style={{ marginTop: 100, marginBottom: 100 }}
            >

                <div className="">
                    <div>
                        <SHTText size="h4" text="Purpose" color="white" />

                    </div>


                    <div className="py-3">
                        <SHTText
                            size="p"
                            text="Showtime Flag Football League strives to maintain a professional work environment for journalists covering league games and events and sets the framework for all members of media and content creators with this Media Accreditation framework."
                            color="gray"
                        />
                    </div>
                    <div className="py-3">
                        <SHTText
                            size="p"
                            text="Members of the media and content creators accredited to cover the league games, and its events are required to act respectfully towards their colleagues, athletes, coaching staff, organisers and volunteers. They must at all times act in accordance with international media and journalism ethics principles, maintaining high standards especially in regards to truthfulness, accuracy, objectivity, impartiality, fairness and public accountability."
                            color="gray"
                        />
                    </div>
                    <div className="py-3">
                        <SHTText
                            size="p"
                            text="All content gathered at Showtime Flag league matches, including matches in all league organized games and including the Playoffs and Showtime Bowl Event is protected by Media Accreditation Agreements. This means that editorial match reports and match imagery can only be provided by accredited media companies to legitimate news products or services (via print, online, or mobile). "
                            color="gray"
                        />
                    </div>
                    <div className="py-3">
                        <SHTText
                            size="p"
                            text="The use of match imagery in any commercial context is strictly prohibited unless express permission has been given by the League. Examples of commercial usage include, but are not limited to, use in betting and gaming, marketing, and advertising products or services. "
                            color="gray"
                        />
                    </div>
                    <div className="py-3">
                        <SHTText
                            size="p"
                            text="By requesting accreditation, applicants accept the outlined terms and conditions, and the rules and directives set by the league and agree to respect them during the league games and its events.
 "
                            color="gray"
                        />
                    </div>


                </div>

                <div className="">
                    <div>
                        <SHTText size="h3" text="MEDIA ACCREDITATION FRAMEWORK" color="white" />
                        <SHTText size="h4" text="1. Application " color="white" />

                    </div>



                    <div className="py-3">
                        <SHTText
                            size="p"
                            text="
All accredited members of media and content creators must wear their Media Accreditation Pass and a media bib at all times when working at the League games or events. The media bib is the property of the League and shall be provided to members of the media and content creators at every game day.
All media bibs must be returned to the league officials at the end of each game day or the league event. "
                            color="gray"
                        />
                    </div>
                    <div className="py-3">
                        <SHTText
                            size="p"
                            text="Accreditations must always be visible during the game days or events. Each accreditation is issued specifically for the media member or content creator who was accepted through the accreditation process. Accreditations may not be transferred or loaned to another person for any reason, failure to adhere to this can result in the loss of accreditations for all parties involved. Accreditations may be revoked at any time without cause. "
                            color="gray"
                        />
                    </div>

                </div>

                <div className="">
                    <div>
                        <SHTText size="h3" text="Accreditation Usage" color="white" />


                    </div>



                    <div className="py-3">
                        <SHTText
                            size="p"
                            text="
Accredited members of the media and content creators will receive admission to game days and league events, access to media work areas and any spaces designated for media in the venue. Misuse of the media accreditation will result in the immediate loss of the accreditation, removal from the media areas and the possible loss of accreditation privileges for future events. "
                            color="gray"
                        />
                    </div>


                </div>

                <div className="">
                    <div>
                        <SHTText size="h3" text=" Content Restrictions" color="white" />


                    </div>



                    <div className="py-3">
                        <SHTText size="h6" text="Game Footage:" color="white" />
                        <SHTText
                            size="p"
                            text="Game footage to be used in any single piece of content shall not exceed 10 minutes in length. "
                            color="gray"
                        />
                    </div>
                    <div className="py-3">
                        <SHTText size="h6" text="Coach and Player Interviews:" color="white" />
                        <SHTText
                            size="p"
                            text="Interviews with coaching staff and/or players are permitted only in designated areas and must not interfere with their preparation or recovery.  "
                            color="gray"
                        />
                    </div>
                    <div className="py-3">
                        <SHTText size="h6" text="Branding:" color="white" />
                        <SHTText
                            size="p"
                            text="All content must include the Showtime Flag Football League logo and credit the league appropriately.  "
                            color="gray"
                        />
                    </div>
                    <div className="py-3">
                        <SHTText size="h6" text="Prohibited Content:" color="white" />
                        <SHTText
                            size="p"
                            text="Content must not include any discriminatory, offensive, or inappropriate material. "
                            color="gray"
                        />
                    </div>

                </div>
                <div className="">
                    <div>
                        <SHTText size="h3" text=" Equipment and Setup" color="white" />


                    </div>



                    <div className="py-3">
                        <SHTText size="h6" text="
Permitted Equipment:" color="white" />
                        <SHTText
                            size="p"
                            text="MeHandheld cameras, tripods, and mobile devices are allowed. Drone use is strictly prohibited without prior written consent.mbers "
                            color="gray"
                        />
                    </div>
                    <div className="py-3">
                        <SHTText size="h6" text="Designated Areas:" color="white" />
                        <SHTText
                            size="p"
                            text="Content creators must stay within designated media areas and not obstruct the view of spectators or the operation of the game. "
                            color="gray"
                        />
                    </div>
                    <div className="py-3">
                        <SHTText size="h6" text="Audio Equipment:" color="white" />
                        <SHTText
                            size="p"
                            text="Microphones and other audio recording devices must not interfere with game operations. "
                            color="gray"
                        />
                    </div>
                </div>
                <div className="">
                    <div>
                        <SHTText size="h3" text=" Conduct" color="white" />


                    </div>



                    <div className="py-3">
                        <SHTText size="h6" text="Professional Behavior" color="white" />
                        <SHTText
                            size="p"
                            text=" Accredited members of the media and content creators must always conduct themselves professionally and behave in an orderly manner. All media members and content creators must respect the work environment of their colleagues by maintaining a quiet and professional atmosphere. Any disruptive, abusive or threatening behavior will not be accepted and may result in immediate revocation of media accreditation. "
                            color="gray"
                        />
                    </div>
                    <div className="py-3">
                        <SHTText size="h6" text="Respect for Participants" color="white" />
                        <SHTText
                            size="p"
                            text="Respect the privacy and personal space of players, coaches, and officials. Do not engage in aggressive questioning or behavior. "
                            color="gray"
                        />
                    </div>

                </div>
                <div className="">
                    <div>
                        <SHTText size="h3" text="Content Use and Distribution" color="white" />


                    </div>



                    <div className="py-3">
                        <SHTText
                            size="p"
                            text="The league has exclusive rights to all league footage and requires all footage to be submitted to the League's media office within 36 hours of capture. The league may also require accredited media personnel to be connected to the League's gameday media operations. "
                            color="gray"
                        />
                    </div>
                    <div className="py-3">
                        <SHTText
                            size="p"
                            text="Content captured shall be used for only non-commercial editorial purposes unless expressly permitted by the Showtime Flag Football League. "
                            color="gray"
                        />
                    </div>
                    <div className="py-3">
                        <SHTText
                            size="p"
                            text="The league reserves the right to:
- stop the use of all content if such content does not represent the League in a manner deemed proper by the league;
- request for mandatory submission of content prior to publishing;
- restrict the right to use the content in certain platforms or mediums;
- reduce or extend the embargo on publication of League footage. "
                            color="gray"
                        />
                    </div>
                    <div className="py-3">
                        <SHTText size="h6" text="Distribution Platforms" color="white" />
                        <SHTText
                            size="p"
                            text="Content creators may distribute their content on personal websites, blogs, and social media channels. Content must not be sold or used in paid advertising without prior approval. "
                            color="gray"
                        />
                    </div>

                    <div className="py-3">

                        <SHTText
                            size="p"
                            text="All accredited media and content creators agree that it shall not distribute, broadcast, publish or post any content captured or created at the league games or league events while the games or event is in progress. Content captured or created shall only be published or posted by the media and content creators upon the expiration of 36 hours after any game day or the league event except with the permission of the League. "
                            color="gray"
                        />
                    </div>

                </div>
                <div className="">
                    <div>
                        <SHTText size="h3" text="Legal Compliance" color="white" />

                    </div>



                    <div className="py-3">

                        <SHTText size="h6" text="Copyright Laws: " color="white" />
                        <SHTText
                            size="p"
                            text={`Showtime Flag Football League on behalf of SFFL, is the exclusive copyright holder in and to all Footage of the Showtime Flag Football League (the League Footage). League Footage is defined as league game footage, as well as all league-controlled events (i.e., League Draft, League Combine, etc.). League game footage and League-controlled events shall be collectively referred to herein as “League-controlled footage"). Any use of League-controlled footage (whether acquired through League, from any other approved source, or captured by a third party with proper credentials) requires the express written consent of the League (in the form of a contract) and must be used in compliance with all terms, requirements and restrictions stated therein.

For purposes of this framework, “League game footage" shall include any footage taken of events at any and all game days and events (whether of game action, sidelines, crowd, halftime, etc.) from the period three hours prior to kickoff of a league game to two hours after the  game has ended; and ending upon the completion of the trophy presentation; and the day of the Showtime Bowl, and ending upon completion of the Showtime Bowl trophy presentation and any such post game activities or events organized by the League.`}
                            color="gray"
                        />
                    </div>
                    <div className="py-3">

                        <SHTText size="h6" text="Grant of Rights: " color="white" />
                        <SHTText
                            size="p"
                            text="Subject to the terms of this framework, the League shall grant to the accredited person the rights to capture, publish or post footage from any game day or league events provided the use of such footage is in line with the purpose of the accreditation granted by the League. "
                            color="gray"
                        />
                    </div>

                    <div className="py-3">

                        <SHTText size="h6" text="Liability:" color="white" />
                        <SHTText
                            size="p"
                            text="The Showtime Flag Football League is not liable for any injuries, damage, or loss of equipment incurred by content creators during the event and the media and content creators hereby release the League from any and all liability arising in connection with attending the league games or events.

All media and content creators hereby agrees to indemnify and hold harmless the League from and against all liability, loss, damage or expense arising out of the issuance of this accreditation. "
                            color="gray"
                        />
                    </div>
                </div>
                <div className="">
                    <div>
                        <SHTText size="h3" text=" Revocation of Access" color="white" />
                    </div>



                    <div className="py-3">
                        <SHTText size="h6" text="Violation of Guidelines: " color="white" />
                        <SHTText
                            size="p"
                            text="Any violation of these guidelines may result in the immediate revocation of media access and removal from the event. "
                            color="gray"
                        />
                    </div>
                    <div className="py-3">
                        <SHTText size="h6" text="Discretionary Powers: " color="white" />
                        <SHTText
                            size="p"
                            text="The Showtime Flag Football League reserves the right to revoke media access at its discretion. "
                            color="gray"
                        />
                    </div>

                </div>
                <div className="">
                    <div>
                        <SHTText size="h3" text="Contact Information" color="white" />


                    </div>



                    <div className="py-3">
                        <SHTText size="h6" text="Email: " color="white" />
                        <SHTText
                            size="p"
                            text="helpdesk@sffl.football "
                            color="gray"
                        />
                    </div>
                    <div className="py-3">
                        <SHTText size="h6" text="Phone: " color="white" />
                        <SHTText
                            size="p"
                            text=" +2349036682255 "
                            color="gray"
                        />
                    </div>
                    <div className="py-3">
                        <SHTText size="h6" text="Address: " color="white" />
                        <SHTText
                            size="p"
                            text="10b, Crest Villa Court, Ologolo Road, Lekki Lagos "
                            color="gray"
                        />
                    </div>

                </div>

            </section>
        </Container>
    );
};

export default MediaContent;

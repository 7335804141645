import React from "react";
import SHTSpinner from "../../components/SHTSpinner";
import MediaHeader from "./MediaHeader";
import MediaContent from "./MediaContent";



const MediaPage = () => {
    return (
        <SHTSpinner loading={false}>
            <MediaHeader />
            <MediaContent />
        </SHTSpinner>
    );
};

export default MediaPage;

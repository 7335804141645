import React from "react";
import SHTText from "../../components/SHTText";
import TicketImage from '../../assets/images/svg/ticketImage.svg'
import '../../assets/styles/page/sht_ticket_style.css'
import SHTButton from "../../components/SHTButton";

const BuyTickets = () => {
    return (
        <section className="d-lg-flex justify-content-around align-items-center sht_ticket_container">
            <div className="ticket_side">
                <img src={TicketImage} alt="ticket Image" className="ticket_side_image" />
            </div>
            <div className="text-center  ">
                <SHTText size="h2" color="white_important" text="IT’S FOOTBALL WEEKEND!" />
                <SHTText size="h4" color="white_important" text="MEADOW HALL WAY, LEKKI, LAGOS" />
                <a href="https://tickets.sffl.football/" className="mt-5"><SHTButton name="BUY TICKETS" type="submit" /></a>
            </div>
        </section>
    )
}

export default BuyTickets


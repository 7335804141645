import React, { useState, useEffect } from "react";
import ScoresHeader from "./ScoresHeader";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import ScoreFilter from "./ScoreFilter";
import ScoreDetails from "./ScoreDetails";
import SHTSpinner from "../../components/SHTSpinner";
import Highlights from "./Highlights";
import { Container } from "react-bootstrap";
import { RequestResponseInterface } from "../../interfaces/axios.dto";
import { useNavigate } from "react-router-dom";

const ScorePage = ({ fetchGamesHighlights, fetchGames }: any) => {
  const navigate = useNavigate();
  const [highlights, setHighlights] = useState([]);
  const [scoreDetails, setScoreDetails] = useState([]);
  const [highlightsLoading, setHighlightsLoading] = useState(true);
  const [gamesLoading, setGamesLoading] = useState(true);
  
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    handleFetchLatestHighlight();
  }, []);

  useEffect(() => {
    handleFetchGames();
  }, [currentPage]);

  const handleFetchLatestHighlight = async () => {
    setHighlightsLoading(true);
    await fetchGamesHighlights()
      .then((response: RequestResponseInterface) => {
        if (response.status === 200) {
          setHighlights(response.data.results);
        }
        setHighlightsLoading(false);
      })
      .catch(() => setHighlightsLoading(false));
  };

  const handleFetchGames = async () => {
    setGamesLoading(true);
    await fetchGames({ page: currentPage })
      .then((response: RequestResponseInterface) => {
        if (response.status === 200) {
          setTotalPages(response.data.total_pages);
          const newArray = response.data.results.map((obj: any) => ({
            id: obj.id,
            week: obj.week.week_number,
            date: obj.date,
            time: obj.time,
            team1Id: obj.home_team.team_id,
            team2Id: obj.away_team.team_id,
            firstImage: obj.home_team.team_image[0]?.image,
            secondImage: obj.away_team.team_image[0]?.image,
            firstTeam: obj.home_team.team_name,
            secondTeam: obj.away_team.team_name,
            firstScore: obj.home_team.score,
            secondScore: obj.away_team.score,
          }));
          setScoreDetails(newArray);
        }
        setGamesLoading(false);
      })
      .catch(() => setGamesLoading(false));
  };

  const handleNavigate = (id: string) => {
    navigate(`/games/highlights/${id}`);
  };

  const handleViewGameDetails = (id: string, team_id: string) => {
    navigate(`/games/${id}/${team_id}`);
  };

  const PaginationChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <SHTSpinner loading={false}>
      <ScoresHeader />
      <ScoreFilter
        subTitle="SHOWTIME SCORES"
        title={"GAMES"}
        handleFilter={() => handleFetchGames()}
      />
      <Container className="sht_container_space">
        <div className="row score_page_sides">
          <div className="col-lg-8 col-sm-12">
            <ScoreDetails
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={PaginationChange}
              scoreCardDetails={scoreDetails}
              loading={gamesLoading}
              handleClick={(id: string) => handleNavigate(id)}
              handleViewGameDetails={handleViewGameDetails}
            />
          </div>
          <div className="col-lg-4 col-sm-12">
            <Highlights
              highlights={highlights}
              loading={highlightsLoading}
              handleOnClick={(id: any) => handleNavigate(id)}
            />
          </div>
        </div>
      </Container>
    </SHTSpinner>
  );
};

const mapStateToProps = ({ newsReducer }: any) => ({
  newsReducer,
});

export default connect(mapStateToProps, actions)(ScorePage);

import { FC } from "react";
import "../assets/styles/components/sht_card.css";
import SHTText from "./SHTText";
import { Link } from "react-router-dom";
import { formatDate, truncateCharacters } from "../utils/helper";
import ReactPlayer from "react-player";

interface CardInputFieldProps {
  id?: string;
  image?: string;
  video?: string;
  date?: string;
  title?: string;
  subText?: string;
  children?: React.ReactNode;
  style?: any;
  alt?: string;
  handleOnClick: any;
}

const SHTCard: FC<CardInputFieldProps> = ({
  id,
  image,
  video,
  date,
  title,
  subText,
  style,
  handleOnClick,
  alt,
}) => {
  return (
    <div className="sh_card_body">
      <div >
        <div className="sh_card_image_card">
          {video ? (
            <div className="">
              <ReactPlayer
                url={video}
                width="100%"
                height="176px"
                controls={false}
              />
            </div>
          ) : (
            <img
              src={image || "https://via.placeholder.com/252x168"}
              alt={alt}
              className="sh_card_image"
            />
          )}
        </div>
        <div className="sh_card_body_main">
          <div className="sh_card_date">
            <SHTText text={formatDate(date)} size="small" color="gray" />
          </div>
          <div className="sh_card_title">
            <div className="">
              <SHTText text={truncateCharacters(title, 50)} size="h5" color="white" />
            </div>
          </div>
          <div className="sh_card_text">
            {subText && (
              <SHTText
                text={truncateCharacters(subText, 100)}
                size="small"
                color="gray"
              />
            )}
          </div>
          <div className="sh_card_link read_more" onClick={() => handleOnClick(id)}>
            <SHTText text="READ MORE" size="link" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SHTCard;
